import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
import 'primereact/resources/primereact.min.css';          // Core PrimeReact CSS
import 'primeicons/primeicons.css';
import { LOCATION1_URL, LOCATION2_URL, LOCATION3_URL, LOCATION4_URL, HAZARD_DESC_TYPE_ID, HAZARD_TYPE_CATEGORY_ID, LOCATION5_URL, LOCATION6_URL, WORK_ACTIVITIES_URL, TIER3_TIER4_URL, TIER4_TIER5_URL, ALL_LOCATION1_URL, GET_USERS_BY_ROLE, HAZARD_CATEGORY, QR_OBSERVATION_REPORT_URL, QR_OBSERVATION_REPORT_URL_WITH_ID } from '../constants';
import API from '../services/api';
// import LocationSelector from './LocationSelector';

const ObservationFormEdit = () => {
    // Access the id and mode from the URL
    const { id, mode } = useParams();
    console.log(mode)
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    const [businessUnits, setBusinessUnits] = useState([]);
    const [qroAssignee, setQroAssignee] = useState([])
    const [actionAssignee, setActionAssignee] = useState([])
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');

    const [projects, setProjects] = useState([]);
    const [selectedProjectData, setSelectedProjectData] = useState('');

    const [levels, setLevels] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState('');

    const [zones, setZones] = useState([]);
    const [selectedZone, setSelectedZone] = useState('');

    const [category, setCategory] = useState('Environment');
    const [observationType, setObservationType] = useState('Safe');
    const [observationCondition, setObservationCondition] = useState('Act');
    const [rectifiedOnSpot, setRectifiedOnSpot] = useState(false);
    const [workActivities, setWorkActivities] = useState([]);
    const [selectedWorkActivity, setSelectedWorkActivity] = useState('');

    const [safeDescription, setSafeDescription] = useState('');
    const [generalCondition, setGeneralCondition] = useState('');
    const [atRiskDescription, setAtRiskDescription] = useState('');
    const [actionsToBeTaken, setActionsToBeTaken] = useState('');
    const [actionTakenDescription, setActionTakenDescription] = useState('');
    const [actionAssignedTo, setActionAssignedTo] = useState('');
    const [dueDate, setDueDate] = useState(new Date());
    const [role, setRole] = useState('');
    const [hideForm, setHideForm] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [hazardCategory, setHazardCategory] = useState([])
    const [selectHazardCategory, setSelectHazardCategory] = useState('')
    const [selectHazardType, setSelectHazardType] = useState('')
    const [hazardType, setHazardType] = useState([])
    const [hazardDescription, setHazardDescription] = useState([])
    const [selectHazardDescription, setSelectHazardDescription] = useState('')
    const [qrLink, setQrLink] = useState('')

    useEffect(() => {
        // Start the tier-based fetch process from the selected project (which has locationThreeId)
        if (id) {
            getObservation(id)
            // getAllLocations(id)
            getAllHazard()
        }
    }, [id]);
    const getAllHazard = async () => {
        try {
            const response = await API.get(HAZARD_CATEGORY);

            if (response.status === 200) {
                setHazardCategory(response.data.map((activity) => ({
                    label: activity.name, // Assuming the response contains "name" as the key
                    value: activity.id,   // Assuming the response contains "id" as the key
                })));

            } else {
                console.error(`Error: Received status code ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching inspector data:', error);
        }
    }

    useEffect(() => {
        if (selectHazardCategory) {
            getAllHazardType()
        }

    }, [selectHazardCategory])


    const getAllHazardType = async () => {
        try {
            const response = await API.get(HAZARD_TYPE_CATEGORY_ID(selectHazardCategory));

            if (response.status === 200) {
                setHazardType(response.data.map((activity) => ({
                    label: activity.name, // Assuming the response contains "name" as the key
                    value: activity.id,   // Assuming the response contains "id" as the key
                })));
                const observationCategory = observationType === 'Safe' ? 'Safe' : 'Unsafe';
                const result = response.data.find((item) => item?.name?.toLowerCase() === observationCategory.toLowerCase()); // Safely check `name`
                console.log(result.id)

                setSelectHazardType(result.id)

            } else {
                console.error(`Error: Received status code ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching inspector data:', error);
        }
    }

    useEffect(() => {
        console.log(selectHazardType)
        if (selectHazardType) {
            getAllHazardDescription()
        }

    }, [selectHazardType])


    const getAllHazardDescription = async () => {
        try {
            const response = await API.get(HAZARD_DESC_TYPE_ID(selectHazardType));

            if (response.status === 200) {
                setHazardDescription(response.data.map((activity) => ({
                    label: activity.name, // Assuming the response contains "name" as the key
                    value: activity.id,   // Assuming the response contains "id" as the key
                })));

            } else {
                console.error(`Error: Received status code ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching inspector data:', error);
        }
    }

    useEffect(() => {
        if (selectedProjectData) {
            fetchLevels(selectedProjectData);
        }
    }, [selectedProjectData]);

    // Trigger when a level is selected to load zones (Tier 4 -> Tier 5)
    useEffect(() => {
        if (selectedLevel) {
            fetchZones(selectedLevel);
        }
    }, [selectedLevel]);



    const getObservation = async (id) => {

        const params = {
            "include": [{ "relation": "workActivity" }, { "relation": "locationOne" }, { "relation": "locationTwo" }, { "relation": "locationThree" }, { "relation": "locationFour" }, { "relation": "locationFive" }, { "relation": "locationSix" }, { "relation": "hazardCategory" }, { "relation": "hazardDescription" }, { "relation": "hazardType" }]

        };
        const response = await API.get(`${QR_OBSERVATION_REPORT_URL_WITH_ID(id)}?filter=${encodeURIComponent(JSON.stringify(params))}`);
        if (response.status === 200) {
            console.log(response)
            getAllLocations();
            setRole(response.data.qrRole)
            setSelectedZone(response.data.locationSixId)
            setSelectedLevel(response.data.locationFiveId)
            setSelectedProjectData(response.data.locationFourId)
            setSelectedBusinessUnit(response.data.locationThreeId)
            setObservationCondition(response.data.conditionAct ?? 'Act')
            setSelectedCity(response.data.locationTwoId)
            setSelectedCountry(response.data.locationOneId)
            setObservationType(response.data.type)
            setGeneralCondition(response.data.generalCondition)
            setSafeDescription(response.data.describeSafekObservation)
            setAtRiskDescription(response.data.describeAtRiskObservation)
            setActionTakenDescription(response.data.describeActionTaken)
            setCategory(response.data.category)
            setRectifiedOnSpot(response.data.rectifiedStatus === 'Yes' ? true : false)
            setSelectedWorkActivity(response.data.workActivityId)
            setUploadedFiles(response.data.uploads)
            setSelectHazardCategory(response.data.hazardCategoryId)
            setSelectHazardType(response.data.hazardTypeId)
            setSelectHazardDescription(response.data.hazardDescriptionId)
            setQrLink(response.data.qrLink)


        }

    }

    const getAllLocations = async () => {
        const responseOne = await API.get(ALL_LOCATION1_URL);
        const mappedLocationOne = responseOne.data.map((activity) => ({
            label: activity.name, // Assuming the response contains "name" as the key
            value: activity.id,   // Assuming the response contains "id" as the key
        }));
        setCountries(mappedLocationOne)

        const responseTwo = await API.get(LOCATION2_URL);
        const mappedLocationTwo = responseTwo.data.map((activity) => ({
            label: activity.name, // Assuming the response contains "name" as the key
            value: activity.id,   // Assuming the response contains "id" as the key
        }));
        setCities(mappedLocationTwo)

        const responseThree = await API.get(LOCATION3_URL);
        const mappedLocationThree = responseThree.data.map((activity) => ({
            label: activity.name, // Assuming the response contains "name" as the key
            value: activity.id,   // Assuming the response contains "id" as the key
        }));
        setBusinessUnits(mappedLocationThree)

        const responseFour = await API.get(LOCATION4_URL);
        const mappedLocationFour = responseFour.data.map((activity) => ({
            label: activity.name, // Assuming the response contains "name" as the key
            value: activity.id,   // Assuming the response contains "id" as the key
        }));
        setProjects(mappedLocationFour)

        // const responseFive = await API.get(LOCATION5_URL);
        // const mappedLocationFive = responseFive.data.map((activity) => ({
        //     label: activity.name, // Assuming the response contains "name" as the key
        //     value: activity.id,   // Assuming the response contains "id" as the key
        // }));
        // setLevels(mappedLocationFive)

        // const responseSix = await API.get(LOCATION6_URL);
        // const mappedLocationSix = responseSix.data.map((activity) => ({
        //     label: activity.name, // Assuming the response contains "name" as the key
        //     value: activity.id,   // Assuming the response contains "id" as the key
        // }));
        // setZones(mappedLocationSix)



    }

    useEffect(() => {
        if (selectedCountry && selectedCity && selectedBusinessUnit && selectedProjectData) {
            getActionAssignee()
        }
    }, [selectedCountry, selectedCity, selectedBusinessUnit, selectedProjectData])
    const getActionAssignee = async () => {
        try {
            const response = await API.post(GET_USERS_BY_ROLE, {
                locationOneId: selectedCountry,
                locationTwoId: selectedCity,
                locationThreeId: selectedBusinessUnit,
                locationFourId: selectedProjectData,
                mode: 'obs-action-assignee'
            });

            if (response.status === 200) {
                setActionAssignee(response.data.map((activity) => ({
                    label: activity.firstName, // Assuming the response contains "name" as the key
                    value: activity.id,   // Assuming the response contains "id" as the key
                })));
            } else {
                console.error(`Error: Received status code ${response.status}`);
            }
        } catch (error) {
            console.error('Error fetching inspector data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reportData = {
            type: observationType,
            generalCondition: generalCondition,
            describeSafekObservation: safeDescription,
            describeAtRiskObservation: atRiskDescription,
            describeActionTaken: actionTakenDescription,
            category: category,
            rectifiedStatus: rectifiedOnSpot ? 'Yes' : 'No',
            evidences: [], // Or file paths if already uploaded
            remarks: '',
            conditionAct: observationCondition,
            actionTaken: actionTakenDescription,
            actionToBeTaken: actionsToBeTaken,
            dueDate: dueDate ? dueDate.toISOString() : null,
            status: 'Open',
            locationFiveId: selectedLevel,
            locationSixId: selectedZone,
            modified: new Date().toISOString(),
            active: true,
            workActivityId: selectedWorkActivity,
            hazardCategoryId: selectHazardCategory,
            hazardTypeId: selectHazardType,
            hazardDescriptionId: selectHazardDescription,

            // Add other IDs like location IDs here

            actionOwnerId: actionAssignedTo

        };

        // Submit data to API
        try {
            const response = await API.patch(QR_OBSERVATION_REPORT_URL_WITH_ID(id), reportData);
            if (response.status === 204) {
                alert('Submitted!')
                setHideForm(true)
            }

        } catch (error) {
            console.error('Error submitting report:', error);
        }
    };

    useEffect(() => {
        if (selectedProjectData) {
            fetchLevels(selectedProjectData);
        }
    }, [selectedProjectData]);

    // Trigger when a level is selected to load zones (Tier 4 -> Tier 5)
    useEffect(() => {
        if (selectedLevel) {
            fetchZones(selectedLevel);
        }
    }, [selectedLevel]);

    const fetchLevels = async (projectId) => {
        try {
            const response = await API.get(TIER3_TIER4_URL(projectId));
            const mappedLevels = response.data.map((level) => ({
                label: level.name, // Assuming response has `name`
                value: level.id,   // Assuming response has `id`
            }));
            setLevels(mappedLevels);
        } catch (error) {
            console.error('Error fetching levels:', error);
        }
    };

    // Fetch zones based on selected level (Tier 4 -> Tier 5)
    const fetchZones = async (levelId) => {
        try {
            const response = await API.get(TIER4_TIER5_URL(levelId));
            const mappedZones = response.data.map((zone) => ({
                label: zone.name, // Assuming response has `name`
                value: zone.id,   // Assuming response has `id`
            }));
            setZones(mappedZones);
        } catch (error) {
            console.error('Error fetching zones:', error);
        }
    };

    useEffect(() => {
        // Fetching workplace activities from the API when the component mounts
        fetchWorkActivities();
    }, []);

    const fetchWorkActivities = async () => {
        try {
            const response = await API.get(WORK_ACTIVITIES_URL);
            const data = response.data;

            // Map the data to a suitable format if necessary
            const mappedWorkActivities = data.map((activity) => ({
                label: activity.title, // Assuming the response contains "name" as the key
                value: activity.id,   // Assuming the response contains "id" as the key
            }));

            setWorkActivities(mappedWorkActivities);
        } catch (error) {
            console.error('Error fetching work activities:', error);
        }
    };

    // Dropdown options for role
    const roles = [
        { label: 'STT GDC Management', value: 'STT GDC Management' },
        { label: 'Visitor', value: 'Visitor' },
        { label: 'VIRTUS Management', value: 'VIRTUS Management' },
        { label: 'Main Contractor', value: 'Main Contractor' },
        { label: 'Sub-contractor', value: 'Sub-contractor' }
    ];


    const isImageFile = (file) => file.type.startsWith('image/');

    if (mode !== qrLink) {
        return (
            <div className="container publicObs-form mt-4">
                <p className='text-center mb-4'>This observation has already been reviewed (or approved).</p>
                <h2 className='text-center mb-4'>Thank You!</h2>
            </div>
        )
    }

    const handleRemove = (fileIndex) => {
        // Remove file based on index
        setUploadedFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
    };
    return (
        <div className="container publicObs-form mt-4">
            {hideForm && <h2 className='text-center mb-4'>Observation Submitted!</h2>}
            {!hideForm && <> <h2 className='text-center mb-4'>Review Observation</h2>
                <form>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role">Country</label>
                            <Dropdown disabled className="w-100" id="role" value={selectedCountry} options={countries} placeholder="Select" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role">City</label>
                            <Dropdown disabled className="w-100" id="role" value={selectedCity} options={cities} onChange={(e) => setSelectedCity(e.value)} placeholder="Select" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role">Business Unit</label>
                            <Dropdown disabled className="w-100" id="role" value={selectedBusinessUnit} options={businessUnits} onChange={(e) => setSelectedBusinessUnit(e.value)} placeholder="Select" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role">Project / DC Name</label>
                            <Dropdown disabled className="w-100" id="role" value={selectedProjectData} options={projects} onChange={(e) => setSelectedProjectData(e.value)} placeholder="Select" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="level">Level</label>
                            <Dropdown
                                className="w-100"
                                id="level"
                                value={selectedLevel}
                                options={levels}
                                onChange={(e) => setSelectedLevel(e.value)}
                                placeholder="Select Level"
                                disabled={!selectedProjectData} // Disabled until project is selected
                            />
                        </div>
                    </div>

                    {/* Zone Dropdown (Tier 4 -> Tier 5) */}
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="zone">Zone</label>
                            <Dropdown
                                className="w-100"
                                id="zone"
                                value={selectedZone}
                                options={zones}
                                onChange={(e) => setSelectedZone(e.value)}
                                placeholder="Select Zone"
                                disabled={!selectedLevel} // Disabled until level is selected
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="role">Select Your Role</label>
                            <Dropdown disabled className="w-100" id="role" value={role} options={roles} onChange={(e) => setRole(e.value)} placeholder="Select" />
                        </div>
                    </div>



                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label className='mb-3'>Category</label>
                            <div className="d-flex">
                                <RadioButton inputId="category1" name="category" value="Environment" checked={category === 'Environment'} onChange={(e) => setCategory(e.value)} />
                                <label htmlFor="category1" className="ms-2">Environment</label>
                                <RadioButton inputId="category2" name="category" value="Health" checked={category === 'Health'} onChange={(e) => setCategory(e.value)} className="ms-4" />
                                <label htmlFor="category2" className="ms-2">Health</label>
                                <RadioButton inputId="category3" name="category" value="Safety" checked={category === 'Safety'} onChange={(e) => setCategory(e.value)} className="ms-4" />
                                <label htmlFor="category3" className="ms-2">Safety</label>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className='mb-3'>Type of Observation</label>
                            <div className="d-flex">
                                <RadioButton inputId="observationType1" name="observationType" value="Safe" checked={observationType === 'Safe'} onChange={(e) => setObservationType(e.value)} />
                                <label htmlFor="observationType1" className="ms-2">Safe</label>
                                <RadioButton inputId="observationType2" name="observationType" value="At-Risk" checked={observationType === 'At-Risk'} onChange={(e) => setObservationType(e.value)} className="ms-4" />
                                <label htmlFor="observationType2" className="ms-2">At-Risk</label>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">

                            <div className="d-flex">
                                <RadioButton inputId="observationCondition1" name="observationCondition" value="Condition" checked={observationCondition === 'Condition'} onChange={(e) => setObservationCondition(e.value)} />
                                <label htmlFor="observationCondition1" className="ms-2">Condition</label>
                                <RadioButton inputId="observationType2" name="observationCondition" value="Act" checked={observationCondition === 'Act'} onChange={(e) => setObservationCondition(e.value)} className="ms-4" />
                                <label htmlFor="observationCondition2" className="ms-2">Act</label>


                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="zone">Hazard Category</label>
                            <Dropdown
                                className="w-100"
                                id="zone"
                                value={selectHazardCategory}
                                options={hazardCategory}
                                onChange={(e) => setSelectHazardCategory(e.value)}
                                placeholder="Select Hazard Category"
                            // Disabled until level is selected
                            />
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="zone">Hazard Type</label>
                            <Dropdown
                                className="w-100"
                                id="zone"
                                // value={selectHazardType}
                                options={hazardType}
                                onChange={(e) => setSelectHazardType(e.value)}
                                placeholder="Select Hazard Type"
                            // Disabled until level is selected
                            />
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="zone">Hazard Description</label>
                            <Dropdown
                                className="w-100"
                                id="zone"
                                value={selectHazardDescription}
                                options={hazardDescription}
                                onChange={(e) => setSelectHazardDescription(e.value)}
                                placeholder="Select Hazard Description"
                            // Disabled until level is selected
                            />
                        </div>
                    </div>


                    <div className="mb-3">
                        <label htmlFor="workplaceActivity">Workplace Activity</label>
                        <Dropdown
                            className='w-100'
                            id="workActivity"
                            value={selectedWorkActivity}
                            options={workActivities}
                            onChange={(e) => setSelectedWorkActivity(e.value)}
                            placeholder="Select Activity"
                        />
                    </div>

                    {/* <div className="mb-3">
                        <label htmlFor="safeDescription">General Condition</label>
                        <InputTextarea className="w-100" id="safeDescription" value={generalCondition} onChange={(e) => setGeneralCondition(e.target.value)} rows={3} />
                    </div> */}

                    {observationType === 'Safe' && (
                        <>
                            <div className="mb-3">
                                <label htmlFor="safeDescription">Safe Observation Description</label>
                                <InputTextarea className="w-100" id="safeDescription" value={safeDescription} onChange={(e) => setSafeDescription(e.target.value)} rows={3} />
                            </div>

                            <div className="mt-3">
                                <h5>Uploaded Files</h5>
                                <ul className="list-group">
                                    {uploadedFiles && uploadedFiles.map((fileName, index) => {
                                        // Construct the full URL by prepending the base URL
                                        const fileUrl = `https://dev.stt-user.acuizen.com/docs/${fileName}`;

                                        // Check if the file is an image by its extension
                                        const isImageFile = (name) => {
                                            const extension = name.split('.').pop().toLowerCase();
                                            return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);
                                        };

                                        return (
                                            <li key={index} className="list-group-item ">
                                                <div className="d-flex align-items-center">
                                                    {isImageFile(fileName) ? (
                                                        <img
                                                            src={fileUrl}
                                                            alt={fileName}
                                                            width="50"
                                                            height="50"
                                                            className="me-3"
                                                        />
                                                    ) : (
                                                        <i className="pi pi-file" style={{ fontSize: '2em', marginRight: '10px' }}></i>
                                                    )}
                                                    <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                        {fileName}
                                                    </a>
                                                </div>
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => handleRemove(index)}
                                                >
                                                    <i className="pi pi-trash"></i>
                                                </button>
                                            </li>
                                        );
                                    })}

                                </ul>
                            </div>
                        </>
                    )}

                    {observationType === 'At-Risk' && (
                        <>
                            <div className="mb-3">
                                <label>Rectified on the Spot?</label>
                                <div className="d-flex">
                                    <RadioButton inputId="rectifiedYes" name="rectified" value={true} checked={rectifiedOnSpot === true} onChange={(e) => setRectifiedOnSpot(e.value)} />
                                    <label htmlFor="rectifiedYes" className="ms-2">Yes</label>
                                    <RadioButton inputId="rectifiedNo" name="rectified" value={false} checked={rectifiedOnSpot === false} onChange={(e) => setRectifiedOnSpot(e.value)} className="ms-4" />
                                    <label htmlFor="rectifiedNo" className="ms-2">No</label>
                                </div>
                            </div>

                            {rectifiedOnSpot ? (
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="actionTakenDescription">Describe Action Taken</label>
                                        <InputTextarea className="w-100" id="actionTakenDescription" value={actionTakenDescription} onChange={(e) => setActionTakenDescription(e.target.value)} rows={3} />
                                    </div>

                                    <div className="mt-3">
                                        <h5>Uploaded Files</h5>
                                        <ul className="list-group">
                                            {uploadedFiles && uploadedFiles.map((fileName, index) => {
                                                // Construct the full URL by prepending the base URL
                                                const fileUrl = `https://dev.stt-user.acuizen.com/docs/${fileName}`;

                                                // Check if the file is an image by its extension
                                                const isImageFile = (name) => {
                                                    const extension = name.split('.').pop().toLowerCase();
                                                    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);
                                                };

                                                return (
                                                    <li key={index} className="list-group-item ">
                                                        <div className="d-flex align-items-center">
                                                            {isImageFile(fileName) ? (
                                                                <img
                                                                    src={fileUrl}
                                                                    alt={fileName}
                                                                    width="50"
                                                                    height="50"
                                                                    className="me-3"
                                                                />
                                                            ) : (
                                                                <i className="pi pi-file" style={{ fontSize: '2em', marginRight: '10px' }}></i>
                                                            )}
                                                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                                {fileName}
                                                            </a>
                                                        </div>
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleRemove(index)}
                                                        >
                                                            <i className="pi pi-trash"></i>
                                                        </button>
                                                    </li>
                                                );
                                            })}

                                        </ul>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="atRiskDescription">Describe the At-Risk Observation</label>
                                        <InputTextarea className="w-100" id="atRiskDescription" value={atRiskDescription} onChange={(e) => setAtRiskDescription(e.target.value)} rows={3} />
                                    </div>



                                    <div className="mb-3">
                                        <label htmlFor="actionsToBeTaken">Actions to be Taken</label>
                                        <InputTextarea className="w-100" id="actionsToBeTaken" value={actionsToBeTaken} onChange={(e) => setActionsToBeTaken(e.target.value)} rows={3} />
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="actionAssignedTo">Assign Action to</label>
                                            <Dropdown className="w-100" id="actionAssignedTo" value={actionAssignedTo} options={actionAssignee} onChange={(e) => setActionAssignedTo(e.value)} optionLabel="label" placeholder="Select" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="dueDate">Due Date</label>
                                            <Calendar className="w-100" id="dueDate" minDate={new Date()} value={dueDate} onChange={(e) => setDueDate(e.value)} showIcon dateFormat="dd-mm-yy" />
                                        </div>
                                    </div>



                                    <div className="mt-3">
                                        <h5>Uploaded Files</h5>
                                        <ul className="list-group">
                                            {uploadedFiles && uploadedFiles.map((fileName, index) => {
                                                // Construct the full URL by prepending the base URL
                                                const fileUrl = `https://dev.stt-user.acuizen.com/docs/${fileName}`;

                                                // Check if the file is an image by its extension
                                                const isImageFile = (name) => {
                                                    const extension = name.split('.').pop().toLowerCase();
                                                    return ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension);
                                                };

                                                return (
                                                    <li key={index} className="list-group-item ">
                                                        <div className="d-flex align-items-center">
                                                            {isImageFile(fileName) ? (
                                                                <img
                                                                    src={fileUrl}
                                                                    alt={fileName}
                                                                    width="50"
                                                                    height="50"
                                                                    className="me-3"
                                                                />
                                                            ) : (
                                                                <i className="pi pi-file" style={{ fontSize: '2em', marginRight: '10px' }}></i>
                                                            )}
                                                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                                {fileName}
                                                            </a>
                                                        </div>
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => handleRemove(index)}
                                                        >
                                                            <i className="pi pi-trash"></i>
                                                        </button>
                                                    </li>
                                                );
                                            })}

                                        </ul>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <div className="mt-4">
                        <Button label="Submit" type="submit" onClick={handleSubmit} className="p-button-danger" />
                    </div>
                </form>
            </>
            }
        </div >
    );
};

export default ObservationFormEdit;
